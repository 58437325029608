// .form
//  //Type
//  // &--newsletter
//  //     input
//  //     button


// Dark Mode
// .dark-theme
//     .form

.form--newsletter {
    .form__message--error {
        width: 100%;
        color: $color-green;
        display: none;
        font-size: 1.6rem;
        @include media('<phone') {
            font-size: 2.4rem; } } }

.sr-only {
    width: 100%;
    margin-top: 2.4rem;
    display: block; }

.ml-subscribe-form {
    width: 100%; }
.ml-field-group {
    width: 100%; }
.ml-error {
    .form__message--error {
        display: block; } }
.row-success {
    width: 100%;
    margin-top: 2.4rem;
    color: $color-green;
    @include media('<phone') {
        margin-top: 4rem; }
    .ml-form-successContent {
        width: 100%;
        @include flexbox(row, nowrap, center, center, center);
        gap: 1.2rem;
        font-size: 1.6rem;
        font-weight: $font-medium;
        @include media('<phone') {
            font-size: 2.4rem;
            gap: 2.4rem; } } }
