%cover {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0; }

//Flexbox mixins
%center {
    display: flex;
    justify-content: center;
    align-items: center; }
%space-between {
    display: flex;
    justify-content: space-between;
    align-items: center; }
%row-start-stretch {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch; }

@mixin ratio ($height, $width) {
    font-size: 0;
    overflow: hidden;
    position: relative;
    &:after {
        content: '';
        display: inline-block;
        padding-top: $width / $height *100%;
        width: 100%; } }

//Flexbox
@mixin flexbox( $flex-direction, $flex-wrap, $justify-content, $align-items, $align-content) {
    display: flex;
    flex-direction: $flex-direction;
    flex-wrap: $flex-wrap;
    justify-content: $justify-content;
    align-items: $align-items;
    align-content: $align-content; }

//Background Image
@mixin background( $background-image, $background-size, $background-position, $background-repeat) {
    background-image: $background-image;
    background-size: $background-size;
    background-position: $background-position;
    background-repeat: $background-repeat; }
