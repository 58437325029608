// Colors
// $color-black: #1D1E30
$color-off-black: #1A1B16;
// $color-middle-black: #282831
$color-dark-gray: #5F5F5F;
$color-medium-gray: #32342A;
$color-light-gray: #C6C7C2;
// $color-purple-gray: #9CA0C0
$color-beige: #E9EDE0;
$color-off-white: #F5F5F5;
// $color-white: #F5F5F5

$color-green: #CAE23F;


//Gradients

//Borders/Strokes

//Shadows
