input, select {
    background: none;
    border: none;
    outline: none;
    color: inherit;
    &::placeholder {
        color: inherit;
        opacity: 0.5; }
    // &:focus
    // @include media('<phone')
    // &[type="email"]
    //Dark Theme
    // .dark-theme &
    //     &:focus
 }    //     &[type="email"]

// select
//     &:focus
//     option
//     //Dark Theme
//     .dark-theme &
