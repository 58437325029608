.animation--float-y {
    animation: floatY 4s ease infinite; }
.animation--float-y-super {
    animation: floatYSuper 8s ease-out infinite;
    @include media('<phone') {
        animation-duration: 4s; } }
.animation--floaty--reverse {
    animation: floatYReverse 5s ease infinite; }
.animation--floaty--reverse-super {
    animation: floatYReverseSuper 10s ease-out infinite;
    @include media('<phone') {
        animation-duration: 5s; } }
.animation--float-h {
    animation: floatH 4s ease infinite; }
.animation--floath--reverse {
    animation: floatHReverse 6s ease infinite; }
.animation--float-diagonal {
    animation: floatDiagonal 4s ease-in-out infinite; }
.animation--float-diagonal-reverse {
    animation: floatDiagonalReverse 4.5s ease-in-out infinite alternate; }
.animation--zoom--inout {
    animation: zoomIn 2s ease-in-out infinite alternate; }

@keyframes floatY {
    from {
        transform: translate(0, 0); }
    65% {
        transform: translate(0, -1rem); }
    to {
        transform: translate(0, 0); } }
@keyframes floatYSuper {
    from {
        transform: translate(0, 0); }
    65% {
        transform: translate(0, -3rem); }
    to {
        transform: translate(0, 0); } }
@keyframes floatYReverse {
    from {
        transform: translate(0, 0); }
    65% {
        transform: translate(0, 1rem); }
    to {
        transform: translate(0, 0); } }
@keyframes floatYReverseSuper {
    from {
        transform: translate(0, 0); }
    65% {
        transform: translate(0, 3rem); }
    to {
        transform: translate(0, 0); } }
@keyframes floatH {
    from {
        transform: translate(0, 0); }
    65% {
        transform: translate(-1rem, 0); }
    to {
        transform: translate(0, 0); } }
@keyframes floatHReverse {
    from {
        transform: translate(0, 0); }
    65% {
        transform: translate(1rem, 0); }
    to {
        transform: translate(0, 0); } }
@keyframes floatDiagonal {
    0% {
        transform: translate(0, 0); }
    65% {
        transform: translate(-2rem, 1rem); }
    100% {
        transform: translate(0, 0); } }
@keyframes floatDiagonalReverse {
    0% {
        transform: translate(0, 0); }
    65% {
        transform: translate(2rem, -1rem); }
    100% {
        transform: translate(0, 0); } }
@keyframes zoomIn {
    0% {
        transform: scale(1); }
    100% {
        transform: scale(1.05); } }


.animation--rotate {
    animation: rotate 4s ease infinite; }
.animation--rotate-reverse {
    animation: rotateReverse 4.5s ease infinite; }
.animation--rotate-float {
    animation: rotateFloat 4s ease-in infinite; }
.animation--rotate-float-reverse {
    animation: rotateFloatReverse 3s ease-in infinite; }

@keyframes rotate {
    from {
        transform: rotate(0deg) translateY(0); }
    65% {
        transform: rotate(10deg) translateY(0.5rem); }
    to {
        transform: rotate(0deg) translateY(0); } }
@keyframes rotateReverse {
    from {
        transform: rotate(0deg); }
    65% {
        transform: rotate(-15deg) translateY(0.5rem); }
    to {
        transform: rotate(0deg); } }
@keyframes rotateFloat {
    from {
        transform: rotate(0deg) translateY(0); }
    65% {
        transform: rotate(10deg) translateY(-1rem); }
    to {
        transform: rotate(0deg) translateY(0); } }
@keyframes rotateFloatReverse {
    from {
        transform: rotate(0deg) translateY(0); }
    65% {
        transform: rotate(5deg) translateY(1rem); }
    to {
        transform: rotate(0deg) translateY(0); } }



@keyframes activeSwitch {
    from {
        transform: translateX(0);
        width: calc(50% - 0.3rem); }
    65% {
        transform: translateX(0);
        width: calc(100% - 0.6rem); }
    to {
        transform: translateX(100%);
        width: calc(50% - 0.3rem); } }
@keyframes activeSwitchReverse {
    from {
        transform: translateX(100%);
        width: calc(50% - 0.3rem); }
    65% {
        transform: translateX(100%);
        width: calc(100% - 0.6rem); }
    to {
        transform: translateX(0);
        width: calc(50% - 0.3rem); } }


@keyframes floatingGradientTop {
    0% {
        transform: translate(0);
        background-position: 0% 50%; }
    50% {
        transform: translate(20rem, 20rem);
        background-position: 100% 50%; }
    100% {
        transform: translate(0);
        background-position: 0% 50%; } }
@include media('<phone') {
    @keyframes floatingGradientTop {
        0% {
            transform: translate(0);
            background-position: 0% 50%; }
        50% {
            transform: translate(20rem, 40rem);
            background-position: 100% 50%; }
        100% {
            transform: translate(0);
            background-position: 0% 50%; } } }
@keyframes floatingGradientBottom {
    0% {
        transform: translate(0);
        background-position: 0% 50%; }
    50% {
        transform: translate(-20rem, -20rem);
        background-position: 100% 50%; }
    100% {
        transform: translate(0);
        background-position: 0% 50%; } }
@include media('<phone') {
    @keyframes floatingGradientBottom {
        0% {
            transform: translate(0);
            background-position: 0% 50%; }
        50% {
            transform: translate(-20rem, -40rem);
            background-position: 100% 50%; }
        100% {
            transform: translate(0);
            background-position: 0% 50%; } } }

@keyframes cardGradient {
    0% {
        background-position: 100% 50%; }
    50% {
        background-position: 0% 25%; }
    100% {
        background-position: 100% 50%; } }


.animation--in {
    transition: opacity 0.4s 0.3s ease-out, transform 0.6s 0.3s ease-out;
    &:not(.visible) {
        opacity: 0!important;
        transform: translateY(10rem)!important; } }


.animation--in--fade {
    animation: fadeIn 0.5s ease-out; }

@keyframes fadeIn {
    0% {
        opacity: 0; }
    100% {
        opacity: 1; } }

.animation--in--modalSlide {
    animation: modalSlide 1s ease-out; }

@keyframes modalSlide {
    0% {
        transform: translateY(105%);
        opacity: 0; }
    100% {
        transform: translateY(0);
        opacity: 1; } }

.animation--in--modalgrow {
    animation: modalGrow 1s ease-out; }

@keyframes modalGrow {
    0% {
        transform: scale(0.1);
        opacity: 0; }
    100% {
        transform: scale(1);
        opacity: 1; } }
@include media('<phone') {
    @keyframes modalGrow {
        0% {
            transform: translateY(105%);
            opacity: 0; }
        100% {
            transform: translateY(0);
            opacity: 1; } } }
