.coming-soon__wrapper {
    width: 100%;
    height: 100%;
    @include flexbox(column, nowrap, center, center, center);
    padding: 16rem;
    position: absolute;
    top: 0;
    left: 0;
    @include media('<phone') {
        justify-content: flex-end;
        padding: 16rem 4rem 8rem 4rem; } }
.coming-soon__logo {
    width: 15rem;
    overflow: hidden;
    position: relative;
    @include media('<phone') {
        width: 16rem; }
    img {
        filter: none;
        background-blend-mode: none;
        &:nth-child(n+2) {
            opacity: 0;
            position: absolute;
            top: calc(-1 * 0.5rem);
            left: calc(-1 * 1rem);
            width: 100%;
            height: auto; }
        &:nth-child(n+2) {
            animation-duration: 4s;
            animation-delay: 2s;
            animation-timing-function: linear;
            animation-iteration-count: infinite; }
        &:nth-child(2) {
            filter: none;
            background-blend-mode: none;
            animation-name: glitch-anim-1; }
        &:nth-child(3) {
            filter: none;
            background-blend-mode: none;
            animation-name: glitch-anim-2; }
        &:nth-child(4) {
            filter: none;
            background-blend-mode: none;
            animation-name: glitch-anim-3; }
        &:nth-child(5) {
            filter: drop-shadow(-0.5rem 0 $color-green);
            background-blend-mode: none;
            animation-name: glitch-anim-flash; } } }

.coming-soon__title {
    position: absolute;
    font-size: 1.6rem;
    bottom: 10rem;
    left: 50%;
    transform: translateX(-50%);
    @include media('<phone') {
        position: relative;
        bottom: auto;
        left: auto;
        transform: none;
        font-size: 3rem;
        margin-top: 25vh;
        transition: margin 0.5s 0s ease-out;
        &.active {
            margin-top: 15vh; } }
    &::before, &::after {
        content: '';
        width: 1.6rem;
        height: 1.6rem;
        border-radius: 0.4rem;
        background: $color-green;
        position: absolute;
        top: -2.6rem;
        left: 50%;
        transform: translatex(-50%);
        z-index: 1;
        @include media('<phone') {
            width: 2.8rem;
            height: 2.8rem;
            border-radius: 0.8rem;
            top: -4rem; } }
    &::after {
        filter: blur(1rem);
        z-index: 0;
        animation: glowLight 1s ease infinite alternate; } }


.coming-soon__form__wrapper {
    width: auto;
    max-width: 30rem;
    position: absolute;
    top: 2.4rem;
    right: 2.4rem;
    @include flexbox(row, wrap, center, center, center);
    @include media('<phone') {
        position: relative;
        top: auto;
        right: auto;
        margin-top: 12rem; }
    &.active {
        border: 0.15rem solid $color-medium-gray;
        border-radius: 0.8rem;
        padding: 1.6rem;
        @include media('<phone') {
            width: 100%;
            max-width: 100%;
            padding: 2.4rem; } }
    .button {
        border: 0.15rem solid $color-medium-gray;
        border-radius: 0.8rem;
        padding: 1.6rem 1.6rem 1.6rem 4.5rem;
        position: relative;
        white-space: nowrap;
        font-size: 1.2rem;
        font-weight: $font-bold;
        overflow: hidden;
        @include media('<phone') {
            font-size: 2.4rem; }
        &--show-form {
            @include media('<phone') {
                padding: 2.4rem 2.4rem 2.4rem 6rem; }
            &::before {
                content: '';
                width: 1.6rem;
                height: 100%;
                @include background(url('../../../public/images/icons/icon_arrow-right.svg'), 100% auto, center, no-repeat);
                position: absolute;
                top: 0;
                left: 1.6rem;
                filter: brightness(0) invert(1);
                @include media('<phone') {
                    width: 3rem; } }
            &:hover {
                @media (hover: hover) {
                    border-color: $color-green;
                    color: $color-green;
                    &::before {
                        filter: none;
                        transform: translateX(0.5rem); } } } }
        &--hide-form {
            padding: 0.8rem;
            @include media('<phone') {
                padding: 1.6rem; }
            img {
                width: 1.2rem;
                filter: brightness(0) invert(1);
                @include media('<phone') {
                    width: 2rem; } }
            &:hover {
                @media (hover: hover) {
                    border-color: $color-green;
                    img {
                        filter: none; } } } } }
    .form__header {
        width: 100%;
        @include flexbox(row, wrap, space-between, center, center);
        h5 {
            white-space: nowrap;
            font-size: 1.6rem;
            font-weight: $font-bold;
            @include media('<phone') {
                font-size: 2.4rem; } } }
    .form--newsletter {
        width: 100%;
        margin-top: 0.8rem;
        @include media('<phone') {
            margin-top: 1.6rem; }
        input {
            width: 100%;
            border-bottom: 0.15rem solid $color-medium-gray;
            padding: 0.8rem 0;
            font-size: 1.8rem;
            @include media('<phone') {
                font-size: 3rem; } }
        .button {
            width: 100%;
            padding: 1.6rem;
            margin-top: 2.4rem;
            color: transparent;
            @include background(url('../../../public/images/icons/icon_arrow-right.svg'), 2rem auto, center, no-repeat);
            filter: brightness(0) invert(1);
            transition: all 0.2s ease-out;
            @include media('<phone') {
                background-size: 3rem auto;
                margin-top: 4rem; }
            &:hover {
                @media (hover: hover) {
                    border-color: $color-green;
                    filter: none;
                    background-position: 52% center; } } }
        .form__message {
            margin: 0.8rem 0;
            color: $color-green;
            font-size: 1.6rem;
            @include media('<phone') {
                font-size: 2.4rem;
                margin: 1.6rem 0; } } } }







@keyframes glowLight {
    0% {
        opacity: 0.5; }
    100% {
        opacity: 1; } }


@keyframes glitch-anim-1 {
	0% {
		opacity: 1;
		transform: translate3d(var(--gap-horizontal),0,0);
		-webkit-clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
		clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%); }

	2% {
		-webkit-clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
		clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%); }

	4% {
		-webkit-clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
		clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%); }

	6% {
		-webkit-clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
		clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%); }

	8% {
		-webkit-clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
		clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%); }

	10% {
		-webkit-clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
		clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%); }

	12% {
		-webkit-clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
		clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%); }

	14% {
		-webkit-clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
		clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%); }

	16% {
		-webkit-clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
		clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%); }

	18% {
		-webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
		clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%); }

	20% {
		-webkit-clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
		clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%); }

	21.9% {
		opacity: 1;
		transform: translate3d(var(--gap-horizontal),0,0); }

	22%, 100% {
		opacity: 0;
		transform: translate3d(0,0,0);
		-webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
		clip-path: polygon(0 0, 0 0, 0 0, 0 0); } }


@keyframes glitch-anim-2 {
	0% {
		opacity: 1;
		transform: translate3d(calc(-1 * var(--gap-horizontal)),0,0);
		-webkit-clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
		clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%); }

	3% {
		-webkit-clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
		clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%); }

	5% {
		-webkit-clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
		clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%); }

	7% {
		-webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
		clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%); }

	9% {
		-webkit-clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
		clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%); }

	11% {
		-webkit-clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
		clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%); }

	13% {
		-webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
		clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%); }

	15% {
		-webkit-clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
		clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%); }

	17% {
		-webkit-clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
		clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%); }

	19% {
		-webkit-clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
		clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%); }

	20% {
		-webkit-clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
		clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%); }

	21.9% {
		opacity: 1;
		transform: translate3d(calc(-1 * var(--gap-horizontal)),0,0); }

	22%, 100% {
		opacity: 0;
		transform: translate3d(0,0,0);
		-webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
		clip-path: polygon(0 0, 0 0, 0 0, 0 0); } }


@keyframes glitch-anim-3 {
	0% {
		opacity: 1;
		transform: translate3d(0, calc(-1 * var(--gap-vertical)), 0) scale3d(-1,-1,1);
		-webkit-clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
		clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%); }

	1.5% {
		-webkit-clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
		clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%); }

	2% {
		-webkit-clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
		clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%); }

	2.5% {
		-webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
		clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%); }

	3% {
		-webkit-clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
		clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%); }

	5% {
		-webkit-clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
		clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%); }

	5.5% {
		-webkit-clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
		clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%); }

	7% {
		-webkit-clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
		clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%); }

	8% {
		-webkit-clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
		clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%); }

	9% {
		-webkit-clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
		clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%); }

	10.5% {
		-webkit-clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
		clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%); }

	11% {
		-webkit-clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
		clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%); }

	13% {
		-webkit-clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
		clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%); }

	14% {
		-webkit-clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
		clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%); }

	14.5% {
		-webkit-clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
		clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%); }

	15% {
		-webkit-clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
		clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%); }

	16% {
		-webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
		clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%); }

	18% {
		-webkit-clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
		clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%); }

	20% {
		-webkit-clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
		clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%); }

	21.9% {
		opacity: 1;
		transform: translate3d(0, calc(-1 * var(--gap-vertical)), 0) scale3d(-1,-1,1); }

	22%, 100% {
		opacity: 0;
		transform: translate3d(0,0,0);
		-webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
		clip-path: polygon(0 0, 0 0, 0 0, 0 0); } }


/* Flash */
@keyframes glitch-anim-flash {
	0%, 5% {
		opacity: 0.5;
		transform: translate3d(var(--gap-horizontal), var(--gap-vertical), 0); }
	5.5%, 100% {
		opacity: 0;
		transform: translate3d(0, 0, 0); } }
